import validate from "/app/node_modules/nuxt/dist/pages/runtime/validate.js";
import redirect_45eig_45global from "/app/packages/frontend-usagers/src/middleware/redirect-eig.global.js";
import manifest_45route_45rule from "/app/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  redirect_45eig_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "check-demande-sejour-id-param": () => import("/app/packages/frontend-usagers/src/middleware/check-demande-sejour-id-param.js"),
  "check-eig-id-param": () => import("/app/packages/frontend-usagers/src/middleware/check-eig-id-param.js"),
  "check-hebergement-id-param": () => import("/app/packages/frontend-usagers/src/middleware/check-hebergement-id-param.js"),
  "check-organisme-id-param": () => import("/app/packages/frontend-usagers/src/middleware/check-organisme-id-param.js"),
  "check-organisme-is-complet": () => import("/app/packages/frontend-usagers/src/middleware/check-organisme-is-complet.js"),
  "is-connected": () => import("/app/packages/frontend-usagers/src/middleware/is-connected.js")
}